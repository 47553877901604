<!--<template>
    <div>
        <h4>Search</h4>
        <pre>{{productForSearchPage}}</pre>
    </div>
</template>-->
<template>
    <div class="container">
        <div class="row product_section">
            <div class="col-md-12">
                <h4>Search Result for "{{this.$route.params.searchValue}}"</h4>
                <hr>
            </div>
            <div class="col-6 col-md-3" v-for="product in productForSearchPage.products" :key="product.id">
                <div class="product_area product-area-wrap">
                    <!--<pre>{{product}}</pre>-->
                    <figure v-if="product.product_images.length > 0" class="product_img">
                        <router-link :to="{ name: 'product', params: { slug: product.slug, type: product.product_type }, }"><img v-if="product.product_images"
                             :src="'https://savershall.com/suadmin/'+product.product_images[0].image" alt=""/>
                        </router-link>
                        <a v-if="!product.is_wishlist" @click="addWishList(product)" class="favorit">
                            <i class="icon ion-md-heart"></i>
                            <i class="icon ion-md-heart-empty"></i>
                        </a>
                        <a v-if="product.is_wishlist" class="favorit">
                            <i class="icon ion-md-heart" style="display: block !important"></i>
                        </a>
                    </figure>
                    <div v-if="product.product_type ===1 || product.product_type ===3" class="product_title">
                        <router-link class="title-height"
                                     :to="{ name: 'product', params: { slug: product.slug, type: product.product_type }, }">
                            <h4 >{{product.name | truncate(78, '...')}}</h4>
                        </router-link>

                        <p v-if="product.product_price">
                            BDT
                            <span v-if="!proPrice">{{ product.product_price[0].offer_price?product.product_price[0].offer_price: product.product_price[0].price}} <del>{{ product.product_price[0].offer_price > 0?product.product_price[0].price > 0?product.product_price[0].price:'':'' }}</del> TK</span>
                            <span v-if="proPrice">{{proPrice}} TK</span>
                        </p>
                        <div class="product_action" v-if="$store.getters.isExist(product.id)">
                            <button class="minus" @click="deductQty(product)">
                                <i class="icon ion-md-remove"></i>
                            </button>
                            <div class="product_value">
                                      <span class="quantity_value">
                                        {{$store.getters.prodQty(product.id)}}
                                      </span>
                                <small>Product added to your Basket</small>
                            </div>
                            <button :disabled="product.product_price[0].quantity === $store.getters.prodQty(product.id)"
                                    class="plus" @click="addQty(product)">
                                <i class="icon ion-md-add"></i>
                            </button>
                        </div>
                        <div v-if="!$store.getters.isExist(product.id) && product.product_price[0].quantity>0"
                             class="product_action">
                            <button style="width:100%" class="plus product-add-btn" @click="addToBag(product)">
                                Add to bag
                            </button>
                        </div>
                        <div v-if="!$store.getters.isExist(product.id) && product.product_price[0].quantity<=0"
                             class="product_action">
                            <button style="width:100%" class="plus product-add-btn" @click="notifyMe(product)">
                                Sold Out. Notify Me
                            </button>
                        </div>
                    </div>
                    <div v-if="product.product_type ===2" class="product_title">
                        <!--<pre>{{product}}</pre>-->
                        <router-link class="title-height"
                                     :to="{ name: 'product', params: { slug: product.slug, type: product.product_type }, }">
                            <h4 >{{product.name | truncate(78, '...')}}</h4>
                        </router-link>

                        <p v-if="product.min_price">
                        <span v-if="!proPrice">
                            <span v-if="product.min_offer_price > 0">Start At: ৳ {{product.min_offer_price?product.min_offer_price:''}} &nbsp; &nbsp;<del> ৳ {{ product.min_offer_price?product.min_price:''}} </del></span></span>
                            <span v-else="">৳ {{product.min_price}} - {{product.max_price}}</span>

                            <span v-if="proPrice">{{proPrice}} TK</span>
                        </p>
                        <div
                                class="product_action action-details">
                            <router-link class="details"
                                         :to="{ name: 'product', params: { slug: product.slug, type: product.product_type }, }">
                                Details
                            </router-link>
                            <!--<button style="width:100%"  @click="notifyMe(product)">
                                Sold Out. Notify Me
                            </button>-->
                        </div>
                        <!--<div class="product_action" v-if="$store.getters.isExist(product.id)">
                            <button class="minus" @click="deductQty(product)">
                                <i class="icon ion-md-remove"></i>
                            </button>
                            <div class="product_value">
                                          <span class="quantity_value">
                                            {{$store.getters.prodQty(product.id)}}
                                          </span>
                                <small>Product added to your Basket</small>
                            </div>
                            <button
                                    class="plus" @click="addQty(product)">
                                <i class="icon ion-md-add"></i>
                            </button>
                        </div>-->
                        <!--<div v-if="!$store.getters.isExist(product.id)"
                             class="product_action">
                            <button style="width:100%" class="plus product-add-btn" @click="addToBag(product)">
                                Add to bag
                            </button>
                        </div>-->
                        <!--<div v-if="!$store.getters.isExist(product.id)"
                             class="product_action">
                            <button style="width:100%" class="plus product-add-btn" @click="notifyMe(product)">
                                Sold Out. Notify Me
                            </button>
                        </div>-->
                    </div>
                </div>

            </div>
            <div>
                <!--<NotifyModal v-model="showModal" :product="notifyMeData" :modalView="showModal"></NotifyModal>-->
                <div v-if="showModal">
                    <transition name="modal">
                        <div class="modal-mask">
                            <div class="modal-wrapper">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">{{notifyMeData.name}}</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true" @click="showModal = false">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="form-group" style="margin-bottom: 1rem">
                                                <input type="text" class="form-control" v-model="name"
                                                       placeholder="Your Name">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" v-model="mobileNo"
                                                       placeholder="Your Mobile Number">
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" @click="showModal = false">
                                                Close
                                            </button>
                                            <button type="button" class="btn btn-primary"
                                                    @click="notifyMeForSubmit(name, mobileNo, notifyMeData)">Notify Me
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <div class="row product_section">
            <div class="col-md-12">
                <h4>Related Product for "{{this.$route.params.searchValue}}"</h4>
                <hr>
            </div>
            <div class="col-6 col-md-3" v-for="product in productForSearchPage.releted_products" :key="product.id">
                <div class="product_area product-area-wrap">
<!--                    <pre>{{product}}</pre>-->
                    <figure v-if="product.product_images.length > 0" class="product_img">
                        <router-link :to="{ name: 'product', params: { slug: product.slug, type: product.product_type }, }"><img v-if="product.product_images"
                             :src="'https://savershall.com/suadmin/'+product.product_images[0].image" alt=""/>
                        </router-link>
                        <a v-if="!product.is_wishlist" @click="addWishList(product)" class="favorit">
                            <i class="icon ion-md-heart"></i>
                            <i class="icon ion-md-heart-empty"></i>
                        </a>
                        <a v-if="product.is_wishlist" class="favorit">
                            <i class="icon ion-md-heart" style="display: block !important"></i>
                        </a>
                    </figure>
                    <div v-if="product.product_type ===1 || product.product_type ===3" class="product_title">
                        <router-link class="title-height"
                                     :to="{ name: 'product', params: { slug: product.slug, type: product.product_type }, }">
                            <h4 >{{product.name | truncate(78, '...')}}</h4>
                        </router-link>

                        <p v-if="product.product_price">
                            BDT
                            <span v-if="!proPrice">{{ product.product_price[0].offer_price?product.product_price[0].offer_price: product.product_price[0].price}} <del>{{ product.product_price[0].offer_price > 0?product.product_price[0].price > 0?product.product_price[0].price:'':'' }}</del> TK</span>
                            <span v-if="proPrice">{{proPrice}} TK</span>
                        </p>
                        <div class="product_action" v-if="$store.getters.isExist(product.id)">
                            <button class="minus" @click="deductQty(product)">
                                <i class="icon ion-md-remove"></i>
                            </button>
                            <div class="product_value">
                                      <span class="quantity_value">
                                        {{$store.getters.prodQty(product.id)}}
                                      </span>
                                <small>Product added to your Basket</small>
                            </div>
                            <button :disabled="product.product_price[0].quantity === $store.getters.prodQty(product.id)"
                                    class="plus" @click="addQty(product)">
                                <i class="icon ion-md-add"></i>
                            </button>
                        </div>
                        <div v-if="!$store.getters.isExist(product.id) && product.product_price[0].quantity>0"
                             class="product_action">
                            <button style="width:100%" class="plus product-add-btn" @click="addToBag(product)">
                                Add to bag
                            </button>
                        </div>
                        <div v-if="!$store.getters.isExist(product.id) && product.product_price[0].quantity<=0"
                             class="product_action">
                            <button style="width:100%" class="plus product-add-btn" @click="notifyMe(product)">
                                Sold Out. Notify Me
                            </button>
                        </div>
                    </div>
                    <div v-if="product.product_type ===2" class="product_title">
                        <!--<pre>{{product}}</pre>-->
                        <router-link class="title-height"
                                     :to="{ name: 'product', params: { slug: product.slug, type: product.product_type }, }">
                            <h4 >{{product.name | truncate(78, '...')}}</h4>
                        </router-link>

                        <p v-if="product.min_price">
                        <span v-if="!proPrice">
                            <span v-if="product.min_offer_price > 0">Start At: ৳ {{product.min_offer_price?product.min_offer_price:''}} &nbsp; &nbsp;<del> ৳ {{ product.min_offer_price?product.min_price:''}} </del></span></span>
                            <span v-else="">৳ {{product.min_price}} - {{product.max_price}}</span>

                            <span v-if="proPrice">{{proPrice}} TK</span>
                        </p>
                        <div
                                class="product_action action-details">
                            <router-link class="details"
                                         :to="{ name: 'product', params: { slug: product.slug, type: product.product_type }, }">
                                Details
                            </router-link>
                            <!--<button style="width:100%"  @click="notifyMe(product)">
                                Sold Out. Notify Me
                            </button>-->
                        </div>
                        <!--<div class="product_action" v-if="$store.getters.isExist(product.id)">
                            <button class="minus" @click="deductQty(product)">
                                <i class="icon ion-md-remove"></i>
                            </button>
                            <div class="product_value">
                                          <span class="quantity_value">
                                            {{$store.getters.prodQty(product.id)}}
                                          </span>
                                <small>Product added to your Basket</small>
                            </div>
                            <button
                                    class="plus" @click="addQty(product)">
                                <i class="icon ion-md-add"></i>
                            </button>
                        </div>-->
                        <!--<div v-if="!$store.getters.isExist(product.id)"
                             class="product_action">
                            <button style="width:100%" class="plus product-add-btn" @click="addToBag(product)">
                                Add to bag
                            </button>
                        </div>-->
                        <!--<div v-if="!$store.getters.isExist(product.id)"
                             class="product_action">
                            <button style="width:100%" class="plus product-add-btn" @click="notifyMe(product)">
                                Sold Out. Notify Me
                            </button>
                        </div>-->
                    </div>
                </div>

            </div>
            <div>
                <!--<NotifyModal v-model="showModal" :product="notifyMeData" :modalView="showModal"></NotifyModal>-->
                <div v-if="showModal">
                    <transition name="modal">
                        <div class="modal-mask">
                            <div class="modal-wrapper">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">{{notifyMeData.name}}</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true" @click="showModal = false">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="form-group" style="margin-bottom: 1rem">
                                                <input type="text" class="form-control" v-model="name"
                                                       placeholder="Your Name">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" v-model="mobileNo"
                                                       placeholder="Your Mobile Number">
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" @click="showModal = false">
                                                Close
                                            </button>
                                            <button type="button" class="btn btn-primary"
                                                    @click="notifyMeForSubmit(name, mobileNo, notifyMeData)">Notify Me
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                showModal: false,
                notifyMeData: '',
                mobileNo: '',
                name: '',
                proPrice: 0
            }
        },
        name: "Details",
        mounted () {
            this.$store.dispatch('getProductForSearchPage', this.$route.params.searchValue);
        },
        watch: {
            $route (){
                const val = this.$route.params.searchValue;
                console.log("Change====", val)
                this.$store.dispatch('getProductForSearchPage', val);
            }
        },
        computed: {
            ...mapGetters(["productForSearchPage","shoppingBag","accessToken" ]),
        },
        methods: {
            addToBag(product) {
                console.log("===>>>", product)
                if(product.product_type === 1 || product.product_type === 3){
                    product.qty = 1
                    product.cartImage = product.product_images[0].image
                    product.product_price[0].offer_price > 0
                        ? product.unitPrice = product.product_price[0].offer_price
                        : product.unitPrice = product.product_price[0].price

                    this.$store.dispatch("addToBag", product);
                    console.log('product from component', product)
                }else {
                    console.log("===Else>>>", product)
                    product.qty = 1
                    product.cartImage = product.product_images[0].image
                    product.min_offer_price > 0
                        ? product.unitPrice = product.min_offer_price
                        : product.unitPrice = product.min_price

                    this.$store.dispatch("addToBag", product);
                }
                //this.$store.dispatch('sohwCart')

            },
            addQty(product) {
                // console.log('update', product.product_price[0].tier_quantity_three)
                this.$store.dispatch('addQty', product)
                console.log('add', product);
                /*if (product.qty <= product.product_price[0].quantity) {
                    if (product.qty >= product.product_price[0].tier_quantity_three && product.product_price[0].tier_quantity_three !== 0) {
                        this.proPrice = product.product_price[0].tier_price_three
                    } else if (product.qty >= product.product_price[0].tier_quantity_two && product.qty < product.product_price[0].tier_quantity_three) {
                        this.proPrice = product.product_price[0].tier_price_two
                    } else if (product.qty >= product.product_price[0].tier_quantity_one && product.qty < product.product_price[0].tier_price_two) {
                        this.proPrice = product.product_price[0].tier_price_one
                    } else if (product.product_price[0].offer_price) {
                        this.proPrice = product.product_price[0].offer_price
                    } else {
                        this.proPrice = product.product_price[0].price
                    }
                }*/
            },
            deductQty(product) {
                console.log('deduct', product);
                console.log('deductgggg', this.$store.getters.prodQty(product.id));
                if (this.$store.getters.prodQty(product.id) > 0) {
                    this.$store.dispatch('deductQty', product)
                }
                if (this.$store.getters.prodQty(product.id) == 0) {
                    this.$store.dispatch('removeFromCart', product)
                }
                /*if (product.qty <= product.product_price[0].quantity) {
                    if (product.qty >= product.product_price[0].tier_quantity_three && product.product_price[0].tier_quantity_three !== 0) {
                        this.proPrice = product.product_price[0].tier_price_three
                    } else if (product.qty >= product.product_price[0].tier_quantity_two && product.qty < product.product_price[0].tier_quantity_three) {
                        this.proPrice = product.product_price[0].tier_price_two
                    } else if (product.qty >= product.product_price[0].tier_quantity_one && product.qty < product.product_price[0].tier_price_two) {
                        this.proPrice = product.product_price[0].tier_price_one
                    } else if (product.product_price[0].offer_price) {
                        this.proPrice = product.product_price[0].offer_price
                    } else {
                        this.proPrice = product.product_price[0].price
                    }
                }*/

            },
            addWishList(product) {
                if (this.accessToken !== null) {
                    let productId = {
                        product_id: product.id
                    };
                    this.$store.dispatch("addToWishlist", productId).then(res => {
                        console.log("Response Data = ", res);
                        if (res === undefined) {
                            window.alert("Added to Wishlist.");
                            product.is_wishlist = 1;
                        }
                    });
                } else {
                    this.$router.push({name: 'login'}).catch(() => {
                    })
                }

            },
            notifyMe(data) {
                this.notifyMeData = data;
                this.showModal = true
            },
            notifyMeForSubmit(name, phone, product) {
                if (name !== '' && phone !== '' && product.id) {
                    let notifyDataForPass = {
                        name: name,
                        phone: phone,
                        product_id: product.id
                    };
                    this.$store.dispatch("soldOutNotifyMe", notifyDataForPass).then(res => {
                        console.log("Response Data = ", res);
                        if (res === undefined) {
                            window.alert("Successfully Submitted.");
                            this.mobileNo = '';
                            this.name = '';
                        }
                    });
                    console.log("Data for pass: ", notifyDataForPass);
                }
                this.showModal = false
            },
        },

    };
</script>

<style scoped>
    button{
        padding: 0;
    }
    .product_action.action-details {
        text-align: center;
    }
    a.details {
        display: block;
        width: 100%;
        background: #434343;
        color:#ffffff;
        padding: 8px;
    }
    a.title-height {
        min-height: 60px;
        display: block;
    }
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .icon {
        cursor: pointer;
    }

    /* product section start */
    .no-padding {
        padding-left: 0;
        padding-right: 0;
    }

    .product_section {
        /*background: #E7E7E7;*/
        background: transparent !important;
        padding: 25px 0;
    }

    .product_section .product_row {
        margin-right: -8px;
        margin-left: -8px;
    }

    .product_section .product_row [class*="col-"] {
        padding-right: 8px;
        padding-left: 8px;
    }

    .product_section .product_area {
        margin-bottom: 15px;
        background: #fff;
        position: relative;
    }

    .product_section .product_area .offer_tags {
        position: absolute;
        left: 15px;
        top: 15px;
        width: auto;
        z-index: 999;
        max-width: 80px;
    }

    .product_section .product_img {
        position: relative;
        max-height: 390px;
        background: #fff;
        height: auto;
        margin: 0;
        overflow: hidden;
    }

    .product_section .product_img img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .product_section .product_img .favorit {
        position: absolute;
        font-size: 26px;
        top: 8px;
        right: 12px;
        color: #434343;
    }

    .product_section .favorit i.ion-md-heart {
        display: none;
    }

    .product_section .favorit i.ion-md-heart-empty {
        display: block;
    }

    .product_section .favorit:hover i.ion-md-heart,
    .product_section .favorit.active i.ion-md-heart {
        display: block;
    }

    .product_section .favorit:hover i.ion-md-heart-empty,
    .product_section .favorit.active i.ion-md-heart-empty {
        display: none;
    }

    .product_section .product_title {
        text-align: center;
        padding: 25px 15px;
    }

    .product_section .product_title .condition {
        position: absolute;
        bottom: 68px;
        width: 100%;
        left: 0;
        color: #434343;
        font-size: 12px;
        font-weight: 600;
        padding: 0 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .product_section .product_action {
        justify-content: space-between;
        position: relative;
        display: flex;
        overflow: hidden;
        margin-top: 22px;
        align-items: center;
    }

    .product_section .product_action .product_value {
        position: relative;
        text-align: center;
        line-height: 15px;
        overflow: hidden;
        font-size: 16px;
        padding: 4px 6px;
        margin: 0;
        color: #353535;
        transition: all .2s;
        background: #F7F7F7;
    }

    .product_section .product_action .product_value a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .product_section .product_action .product_value span,
    .product_section .product_action .product_value small {
        display: block;
    }

    .product_section .product_action .product_value input {
        display: inline-block;
        background: none;
        font-size: 15px;
        color: #000;
        width: 32px;
        height: 16px;
        border: none;
        outline: none;
        box-shadow: none;
        font-weight: bold;
        text-align: center;
    }

    .product_section .product_action .product_value span {
        font-weight: bold;
        font-size: 15px;
    }

    .product_section .product_action .product_value small {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .product_section .product_action .minus:hover,
    .product_section .product_action .plus:hover {
        background: #353535;
    }

    .product_section .product_action .minus,
    .product_section .product_action .plus {
        background: #434343;
        text-align: center;
        border-radius: 2px;
        line-height: 40px;
        height: 40px;
        color: #fff;
        width: 50px;
        border: none;
        outline: none;
        font-size: 22px;
        min-width: 50px;
        box-shadow: none;
        font-weight: bold;
    }

    .product_section .product_action .btnadd {
        justify-content: center;
        border-radius: 2px;
        align-items: center;
        background: #434343;
        position: absolute;
        display: flex;
        height: 100%;
        color: #fff;
        top: 0;
        left: 0;
        border: none;
        width: 100%;
        outline: none;
        font-size: 15px;
        font-weight: 500;
        box-shadow: none;
        transition: all .1s;
    }

    .product_section .product_action:hover .btnadd {
        top: 100%;
    }

    .product_section .load_more {
        text-transform: uppercase;
        border: 2px solid #434343;
        border-radius: 2px;
        font-size: 16px;
        color: #434343;
        margin: 5px auto 0;
        min-width: 256px;
        padding: 7px 20px;
        font-weight: bold;
        text-align: center;
        display: inline-block;
        transition: all .2s;
    }

    .product_section .load_more:hover {
        background: #434343;
        color: #fff;
    }

    .icon {
        cursor: pointer;
    }

    .product_title h4 {
        height: auto !important;
    }

    .product_title a h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .product-area-wrap:hover .product_title a h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 30;
        -webkit-box-orient: vertical;
    }

    /*Media Queries start here*/
    /*Extra small devices (portrait phones, less than 576px)*/
    @media (max-width: 575.98px) {

        .product_title h4 {
            font-size: .8rem;
            height: 35px;
        }
    }

    /*Small devices (landscape phones, 576px and up)*/
    @media (min-width: 576px) {

    }

    /*Medium devices (tablets, 768px and up)*/
    @media (min-width: 768px) {

    }

    /*Large devices (desktops, 992px and up)*/
    @media (min-width: 992px) {

    }

    /*Extra large devices (large desktops, 1200px and up)*/
    @media (min-width: 1200px) {

    }
</style>
